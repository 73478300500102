import { FieldType } from 'components/helpers/form_fields';
import permissions from 'config/permissions';
import fieldsWithPermission from './fields_with_permission';

const filterFields = () => [
  {
    name: "status",
    label: "Ticket Status",
    type: FieldType.SELECT_FIELD,
    options: [
      { value: "settled", label: "Settled" },
      { value: "disputed", label: "Disputed" },
      { value: "unsettled", label: "Unsettled" },
      { value: "sent_to_court", label: "Sent to Court" },
    ],
  },
];

const notEditableFields = [
  { name: 'violation', label: 'Violation', disabled: true },
  { name: 'dispute_type', label: 'Dispute Type', disabled: true },
  { name: 'created_at', label: 'Created at', disabled: true },
  { name: 'dispute_raised_on', label: 'Dispute raised on', disabled: true },
  { name: 'updated_at', label: 'Updated at', disabled: true },
  { name: 'dispute_raised_by', label: 'Dispute raised by', disabled: true },
  { name: 'plate_number', label: 'Vehicle Plate Number', disabled: true },
];

const editableFields = (ticketStatuses, status) => {

  return ([
    {
      name: 'status',
      label: 'Status',
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: ticketStatuses || [],
      disabled: status === "Settled",
    },
    { name: 'dispute_reason', label: 'Dispute Reason', type: FieldType.TEXT_AREA, rows: 4, disabled: true }
  ]);
};

const fieldsShow = (ticketStatuses, userPermissions, status) => [
  ...notEditableFields,
  ...fieldsWithPermission(
    editableFields(ticketStatuses, status),
    userPermissions,
    permissions.UPDATE_VIOLATION
  )
];

const fieldImages = {
  name: 'images',
  label: '',
  type: FieldType.MULTIPLE_FILE_FIELDS,
  disabled: true
};

const fieldVideos = {
  name: 'videos',
  label: '',
  type: FieldType.MULTIPLE_FILE_FIELDS,
  disabled: true
};

const fieldsNew = (ticketTypes = [],parkingLots = []) => {
  const fields = [
    { name: 'plate_number', label: 'Plate Number', mandatory: true },
    {
      name: 'parking_lot_id',
      label: 'Parking Lot Name (where it occurred)',
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: parkingLots,
      entityName: 'lot'
    },
    {
      name: 'parking_rule_name',
      label: 'Violation Type',
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: ticketTypes
    },
    { name: 'status', label: 'Status', mandatory: true, disabled: true },
    { name: 'assigned_to', label: 'Assigned To', mandatory: true,  disabled: true },
    { name: 'date_and_time', label: 'Date & Time', mandatory: true,  disabled: true },
  ];

  return fields
  
};

export { filterFields, fieldImages, fieldVideos, fieldsShow, fieldsNew };
