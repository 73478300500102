import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form } from "informed";
/* Actions */
import { SET_RECORD, SET_LIST_ELEMENT } from "actions/parking_citation_tickets";
import { invoke } from "actions";
/* API */
import { show, update } from "api/parking/citation_tickets";
import { search as dropdownsSearch } from "api/dropdowns";
/* Base */
import {
  renderFieldsWithGrid,
  renderImageField,
} from "components/base/forms/common_form";
import Breadcrumb from "components/base/breadcrumb";
import CollapsableCard from "components/base/collapsable_card";
import Button from "components/base/button";
/* Helpers */
import {
  fieldImages,
  fieldsShow,
} from "components/helpers/fields/citation_tickets";
import { AlertMessagesContext } from "components/helpers/alert_messages";
import Loader from "components/helpers/loader";
import {
  camelize,
  displayDate,
} from "components/helpers";
/* Module */
import connectRecord from "components/modules/connect_record";
import resourceFetcher from "components/modules/resource_fetcher";
import updateRecord from "components/modules/form_actions/update_record";
import withCurrentUser from "components/modules/with_current_user";
import withFetching from "components/modules/with_fetching";

class Show extends React.Component {
  state = {
    isDropdownFetching: false,
    isSaving: false,
    inputChanged: false,
    dropdowns: {
      statuses: [],
    },
    errors: {},
  };

  imageRef = React.createRef();

  static contextType = AlertMessagesContext;

  isFetching = () => {
    return this.props.isResourceFetching || this.state.isDropdownFetching;
  };

  setFormApi = (formApi) => {
    this.formApi = formApi;
  };

  fieldProps = () => ({
    lSize: 4,
    iSize: 8,
    events: {
      onChange: () => this.setState({ inputChanged: true }),
    },
  });

  save = (values) => {
    const { backPath } = this.props;

    updateRecord.bind(this, update, backPath)({
      status: values.status
    });
  };

  values = () => {
    const { record } = this.props;
    const status = this.state.dropdowns.statuses.find(
      (status) => status.label === record.status
    );
    const values = Object.assign({}, record);
    values.created_at = record?.created_at_parsed;
    values.violation = record?.violation_type || "";
    values.updated_at = record?.updated_at_parsed ? record?.updated_at_parsed : "NA";
    values.status = status?.value;
    values.dispute_type = record?.dispute?.dispute_type
      ? camelize(record?.dispute?.dispute_type)
      : "NA";
    values.dispute_raised_on = record?.dispute?.raised_on? displayDate(
      record?.dispute?.raised_on,
      "ddd, MMM Do YYYY, h:mm:ss a"
    ): "NA";
    values.dispute_raised_by = record?.dispute?.raised_by? record?.dispute?.raised_by : "NA";
    values.dispute_reason = record?.dispute?.detailed_reason? record?.dispute?.detailed_reason : "NA";
    values.plate_number = record?.plate_number? record?.plate_number : "NA";
    values.images = record.violation_photos
      ? record.violation_photos.map((image) => image.url)
      : [];
    return values;
  };

  renderSaveButton = () => {
    const { isSaving } = this.state;
    return (
      <div className="d-flex justify-content-end py-2 pr-4">
        <Button size="md" status="success" type="submit" isLoading={isSaving}>
          Save Changes
        </Button>
      </div>
    );
  };

  fieldPropsImage = (type) => ({
    events: {},
    hideInput: true,
    ref: this.imageRef,
    type,
    multipleFiles: false,
    isMaximized: true,
  });

  renderImage() {
    const { images } = this.formApi ? this.formApi.getValues() : {};
    return (
      <React.Fragment>
        <CollapsableCard
          header={`Images (${
            images?.length || this.values().images?.length || "0"
          })`}
          className="mt-1"
        >
          {renderImageField(fieldImages, this.fieldPropsImage("image"))}
        </CollapsableCard>
      </React.Fragment>
    );
  }

  renderFields() {
    const { currentUserPermissions, record } = this.props;
    const { statuses } = this.state.dropdowns;
    return renderFieldsWithGrid(
      fieldsShow(statuses, currentUserPermissions, record?.status),
      2,
      6,
      { ...this.fieldProps(), errors: this.state.errors }
    );
  }

  renderForm() {
    const { isSaving, inputChanged } = this.state;
    const values = this.values();

    return (
      values.status !== "" &&
      values.violation !== "" && (
        <fieldset disabled={isSaving}>
          <Form
            getApi={this.setFormApi}
            initialValues={values}
            className="mt-4"
            onSubmit={this.save}
          >
            <div className="px-4">{this.renderFields()}</div>
            {inputChanged && this.renderSaveButton()}
            {this.renderImage()}
          </Form>
        </fieldset>
      )
    );
  }

  setDropdowns = (key, data) =>
    this.setState({ dropdowns: { ...this.state.dropdowns, [key]: data } });

  componentDidMount() {
    const { startFetching } = this.props;
    Promise.all([
      startFetching(dropdownsSearch("citation_ticket_statuses_field")).then(
        (response) => this.setDropdowns("statuses", response.data)
      ),
    ]).finally(() => this.setState({ isDropdownFetching: false }));
  }

  render() {
    const { backPath, record } = this.props;

    if (this.isFetching()) {
      return <Loader />;
    }
    return (
      <div className="pb-4">
        <Breadcrumb
          title="Citation Details"
          id={record.id}
          backPath={backPath}
        />
        {!isArrayEmpty(this.state.dropdowns.statuses)
          ? this.renderForm()
          : null}
      </div>
    );
  }
}

Show.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object,
};

function mapDispatch (dispatch) {
  return bindActionCreators(
    { setListElement: invoke(SET_LIST_ELEMENT)},
    dispatch
  );
}

export default connectRecord(
  "parking_citation_ticket",
  SET_RECORD,
  resourceFetcher(show),
  connect(null, mapDispatch)(withFetching(withCurrentUser(Show)))
);

const isArrayEmpty = (array) => {
  if (!Array.isArray(array)) return true;

  return array.length === 0;
};
