import React, { useCallback, useMemo, useReducer } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import Update from "../update";
import styles from "./index.module.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/* API */
import { create } from "api/dispute_types";
import { map, pluck } from "underscore";

const initialValues = {
  isSaving: false,
  errors: {},
  disputeTypes: [],
};

const validDisputeTypes = [
  "other",
  "time",
  "not me",
  "fine already paid",
  "billing",
];

const reducer = (state, action) => {
  const { type, payload } = action;
  const { errors } = state;
  switch (type) {
    case "isSaving":
      return { ...state, isSaving: payload, errors: payload ? {} : errors };
    case "errors":
      return { ...state, errors: payload };
    case "disputeTypes":
      return { ...state, disputeTypes: payload };
    default:
      return { ...state };
  }
};

export const TicketModal = (props) => {
  const [values, dispatch] = useReducer(reducer, initialValues);

  const state = useMemo(() => values, [values]);

  const setState = useCallback(
    (type, payload) => {
      dispatch({ type, payload });
    },
    [dispatch]
  );

  const { modalType, openModal } = props;

  const closeModal = useCallback(() => {
    openModal();
    setState("errors", {});
    setState("innerModal", null);
  }, [openModal, setState]);

  const addDisputeType = useCallback(
    async (data) => {
      if (!validDisputeTypes.includes(data[0]?.toLowerCase())) {
        setState("errors", { name: ["Name is not a valid type"] });
        return;
      }
      setState("isSaving", true);
      try {
        const response = await create({
          data: {
            dispute_type: {
              names: pluck(state.disputeTypes, "label").concat(data),
            },
          },
        });
        setState(
          "disputeTypes",
          map(response.data, (type) => ({ label: type.name, value: type.id }))
        );
      } catch (_error) {
      } finally {
        setState("isSaving", false);
      }
    },
    [setState, state.disputeTypes]
  );

  const closeBtn = useCallback(() => {
    return (
      <FontAwesomeIcon
        icon={faTimes}
        className={styles.closeIcon}
        onClick={closeModal}
      />
    );
  }, [closeModal]);

  return (
    <Modal
      isOpen={!!modalType}
      centered
      contentClassName={styles.citationModal}
      style={{ maxWidth: "480px", width: "100%" }}
    >
      {!!modalType && (
        <ModalHeader close={closeBtn()} className="border-0 pb-0" />
      )}
      <ModalBody>
        {modalType === "dispute_types" && (
          <Update
            addDisputeType={addDisputeType}
            closeModal={closeModal}
            setState={setState}
            {...state}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

const mapState = (state) => {
  return {
    record: state.parking_citation_ticket.records.record,
  };
};

export default connect(mapState, null)(TicketModal);
