import React from 'react';
import PropTypes from 'prop-types';
import ParkingCitationTickets from 'components/pages/parking-citation-tickets/index/index'
import ParkingCitationTicketShow from 'components/pages/parking-citation-tickets/show'
import New from 'components/pages/parking-citation-tickets/new/index'
import renderWithBackPath from 'components/modules/render_with_back_path';

import { Route, Switch, withRouter } from 'react-router';

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/new/`} render={renderWithBackPath(New, `${match.url}/`)} />
    <Route exact path={match.path} component={ParkingCitationTickets} />
    <Route exact path={`${match.path}/:id`} render={renderWithBackPath(ParkingCitationTicketShow, match.path)} />
  </Switch>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);