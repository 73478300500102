import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Form } from "informed";
/* Actions */
import { invoke } from "actions";
import { SET_RECORD, SET_LIST_ELEMENT } from "actions/parking_citation_tickets";
/* API */
import { create } from "api/parking/citation_tickets";
import { search as dropdownsSearch } from "api/dropdowns";
/* Base */
import Breadcrumb from "components/base/breadcrumb";
import {
  renderFieldsWithGrid,
  renderImageField,
} from "components/base/forms/common_form";
import Button from "components/base/button";
/* Helpers */
import { fieldsNew } from "components/helpers/fields/citation_tickets";
import Loader from "components/helpers/loader";
import { FieldType } from "components/helpers/form_fields";
import { AlertMessagesContext } from "components/helpers/alert_messages";
/* Modules */
import saveRecord from "components/modules/form_actions/save_record";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
import moment from "moment";
import { isEmpty } from "underscore";

class New extends React.Component {
  state = {
    isSaving: false,
    dropdowns: {
      ticketTypes: [],
      parkingLots: [],
    },
    isDropdownFetching: true,
    errors: { plate_number: "", parking_lot_id: "", parking_rule_name: "" },
  };

  static contextType = AlertMessagesContext;

  isFetching() {
    const { isDropdownFetching } = this.state;
    return isDropdownFetching;
  }

  setDropdowns = (key, data) =>
    this.setState({ dropdowns: { ...this.state.dropdowns, [key]: data } });

  fieldProps = () => ({
    lSize: 6,
    events: {
      onChange: this.handleInputChange,
    },
  });

  handleInputChange = (event) => {
    const { name } = event?.target || {};
    const { errors } = this.state;
    const updatedErrors = Object.keys(errors)
      .filter((objKey) => objKey !== name)
      .reduce((newObj, key) => {
        newObj[key] = errors[key];
        return newObj;
      }, {});
    this.setState({ errors: updatedErrors });
  };

  setFormApi = (formApi) => {
    this.formApi = formApi;
  };

  validateFields = (values) => {
    let errors = {};
    if (!values?.plate_number)
      errors["plate_number"] = ["Plate Number is required !"];
    if (!values?.parking_lot_id)
      errors["parking_lot_id"] = ["Parking Lot is required !"];
    if (!values?.parking_rule_name)
      errors["parking_rule_name"] = ["Violation Type is required !"];
    return errors;
  };

  save = () => {
    const { values } = this.formApi.getState();
    const validationErrors = this.validateFields(values);
    if (!isEmpty(validationErrors)) {
      this.setState({ errors: validationErrors });
      return;
    }
    const { id, agency_id } = this.props.currentUser;
    const parking_violation_images = this.formApi.getValue(
      "parking_violation_images"
    );
    const citation_ticket = {
      status: "unsettled",
      parking_violation: {
        officer_id: id,
        plate_number: values?.plate_number,
        parking_lot: {
          id: values?.parking_lot_id,
        },
        parking_rule: {
          name: values?.parking_rule_name,
        },
      },
    };
    if (agency_id) {
      citation_ticket.agency_id = agency_id;
    }
    if (!isEmpty(parking_violation_images)) {
      citation_ticket.parking_violation.images = parking_violation_images;
      citation_ticket.parking_violation.uploaded_by = "ADMIN";
    }
    const { backPath } = this.props;
    saveRecord.call(this, create, backPath, citation_ticket);
  };

  renderFields() {
    const { ticketTypes, parkingLots } = this.state.dropdowns;
    return renderFieldsWithGrid(fieldsNew(ticketTypes, parkingLots), 2, 6, {
      ...this.fieldProps(),
      errors: this.state.errors,
    });
  }

  renderSaveButton = () => {
    const { isSaving } = this.state;
    return (
      <div className="d-flex justify-content-end pt-2 pr-4">
        <Button
          size="md"
          status="success"
          onClick={this.save}
          isLoading={isSaving}
        >
          Submit
        </Button>
      </div>
    );
  };

  renderForm() {
    const { isSaving } = this.state;
    const initialValues = {
      status: "Unsettled",
      assigned_to: this.props.currentUser?.name,
      date_and_time: moment(
        Date.now() < 10000000000 ? Date.now() * 1000 : Date.now()
      ).format("ddd, MMM Do YYYY, h:mm a"),
    };
    return (
      <fieldset disabled={isSaving}>
        <Form getApi={this.setFormApi} initialValues={initialValues}>
          <Row className="no-gutters px-5">
            <Col sm={12} md={12}>
              {this.renderFields()}
            </Col>
          </Row>
          <Row className="no-gutters px-5 py-4">
            {renderImageField(
              {
                name: "parking_violation_images",
                label: "Click here to Add Violation Images",
                type: FieldType.MULTIPLE_FILE_FIELDS,
                maxFileNumber: 4,
              },
              this.fieldProps()
            )}
          </Row>
        </Form>
      </fieldset>
    );
  }

  componentDidMount() {
    const { startFetching } = this.props;
    Promise.all([
      startFetching(dropdownsSearch("tickets_types_field")).then((response) =>
        this.setDropdowns("ticketTypes", response.data)
      ),
      startFetching(dropdownsSearch("parking_lot_list")).then((response) =>
        this.setDropdowns("parkingLots", response.data)
      ),
    ]).finally(() => this.setState({ isDropdownFetching: false }));
  }

  render() {
    if (this.isFetching()) {
      return <Loader />;
    }
    const { backPath } = this.props;
    return (
      <div className="pb-4">
        <Breadcrumb title="Create citation ticket" backPath={backPath} />
        {this.renderForm()}
        {this.renderSaveButton()}
      </div>
    );
  }
}

function mapDispatch(dispatch) {
  return {
    ...bindActionCreators(
      {
        setRecord: invoke(SET_RECORD),
        setListElement: invoke(SET_LIST_ELEMENT),
      },
      dispatch
    ),
  };
}

New.propTypes = {
  backPath: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  startFetching: PropTypes.func.isRequired,
};

export default connect(null, mapDispatch)(withFetching(withCurrentUser(New)));
