import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
/* Actions */
import { SET_LIST } from "actions/parking_citation_tickets";
/* API */
import { filterFetcher } from "api/parking/citation_tickets";
/* Base */
import IndexTable from "components/base/table";
/* Helpers */
import { camelize } from "components/helpers";
/* Modules */
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
import styles from "./index.module.sass";
import { TicketModal } from "../ticket-modal";
import BasicListToolbar from "components/base/basic_list_toolbar";
import { filterFields } from "components/helpers/fields/citation_tickets";
import { ReactComponent as SettingIcon } from "assets/setting-gear-icon.svg";
import Button from "components/base/button";

class Index extends React.Component {
  state = {
    modalType: "",
  };

  openModal = (modalType) => {
    let val;
    // Evaluate modalType
    switch (true) {
      case modalType === "dispute_types":
        val = "dispute_types";
        break;
      default:
        break;
    }
    this.setState({ modalType: val });
  };

  isFetching = () => {
    const { isResourceFetching } = this.props;
    return isResourceFetching;
  };

  backgroundColor = (status) => {
    switch (status) {
      case "Open":
        return "secondary";
      case "Settled":
        return "success";
      case "Unsettled":
        return "orange";
      case "Canceled":
        return "danger";
      default:
        return "default";
    }
  };

  renderRecords = () => {
    const { list, match, history } = this.props;

    return list.map((record, idx) => {
      const { status } = record;
      return (
        <tr
          key={idx}
          onClick={() => history.push(`${match.path}/${record.id}`)}
        >
          <td>{record?.id}</td>
          <td onClick={(e) => e.stopPropagation()}>
            <Link to={`/dashboard/violations/${record?.violation_id}`}>
              {record?.violation_id}
            </Link>
          </td>
          <td>
            {record?.violation_type ? camelize(record?.violation_type) : "N/A"}
          </td>
          <td>{record?.plate_number ? record?.plate_number : "N/A"}</td>
          <td>
            <div className="d-flex align-items-center">
              <span
                className={`${styles.icon} ${
                  styles[`icon-${this.backgroundColor(status)}`]
                }`}
              ></span>
              <span>{record?.status ? camelize(record?.status) : "N/A"}</span>
            </div>
          </td>
          <td>{record?.created_at_parsed}</td>
          <td>{record?.updated_at_parsed}</td>
          <td>
            {record?.dispute?.dispute_type
              ? camelize(record.dispute.dispute_type)
              : "N/A"}
          </td>
        </tr>
      );
    });
  };

  render() {
    const { modalType } = this.state;
    return (
      <React.Fragment>
        <IndexTable
          {...this.props}
          isFetching={this.isFetching}
          toolbar={
            <BasicListToolbar
              {...this.props}
              label="Create New "
              title="Parking / Citation Tickets"
              showFilters={true}
              extraButtons={() => (
                <Button
                  status={
                    modalType === "dispute_types"
                      ? "activeSecondary"
                      : "secondary"
                  }
                  className={`${styles.btnFilter} ${
                    modalType === "dispute_types" ? styles.activeSettingTab : ""
                  } mr-2`}
                  icon={
                    <SettingIcon
                      className={
                        modalType === "dispute_types"
                          ? styles.activeSettingIcon
                          : ""
                      }
                    />
                  }
                  onClick={() => {
                    this.openModal("dispute_types");
                  }}
                  size="md"
                ></Button>
              )}
            />
          }
          filterFetcher={filterFetcher}
          filterFields={filterFields()}
          resource={resource}
          columns={
            <React.Fragment>
              <th attr="id" style={{ width: "15%", minWidth: "150px" }}>
                ID
              </th>
              <th
                attr="violation_type_id"
                style={{ width: "15%", minWidth: "150px" }}
              >
                Violation Ticket ID
              </th>
              <th
                attr="violation_type"
                style={{ width: "15%", minWidth: "150px" }}
              >
                Violation Type
              </th>
              <th
                attr="plate_number"
                style={{ width: "15%", minWidth: "150px" }}
              >
                Vehicle LPN
              </th>
              <th attr="status" style={{ width: "15%", minWidth: "150px" }}>
                Status
              </th>
              <th attr="created_at" style={{ width: "25%", minWidth: "210px" }}>
                Created At
              </th>
              <th attr="updated_at" style={{ width: "25%", minWidth: "210px" }}>
                Updated At
              </th>
              <th
                attr="dispute_type"
                style={{ width: "15%", minWidth: "150px" }}
              >
                Dispute Type
              </th>
            </React.Fragment>
          }
          renderRecords={this.renderRecords}
        />
        <TicketModal
          modalType={this.state.modalType}
          openModal={this.openModal}
        />
      </React.Fragment>
    );
  }
}

Index.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const resource = "parking_citation_ticket";

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  withFetching(withCurrentUser(Index))
);
