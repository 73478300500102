const colorPalette = [
  '#3A9CED',
  '#27CEBB',
  '#495259',
  '#DFE1E6',
  '#FB745B',
  '#FED83B',
  '#1C6B94',
  '#27CE6A',
  '#242E42',
  '#595249',
  '#F97714',
  '#A274E8',
  '#29D9EE',
  '#D978E3',
  '#1ED698'
];

export default colorPalette;
