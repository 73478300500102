import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
  } from "react";
  import { Badge, Col, Container } from "reactstrap";
  import { Form } from "informed";
  import { isEmpty, map } from "underscore";
  /* Base */
  import Button from "components/base/button";
  /* Api */
  import { search as dropdownsSearch } from "api/dropdowns";
  import { create } from "api/dispute_types";
  /* Helpers */
  import Loader from "components/helpers/loader";
  import { AlertMessagesContext } from "components/helpers/alert_messages";
  /* Module */
  import withFetching from "components/modules/with_fetching";
  /* Assets and Styles */
  import styles from "./index.module.sass";
  import { camelize } from "components/helpers";
  
  const state = {
    selectedCheckboxes: [],
  };
  const { selectedCheckboxes } = state;
  const DisputeTypes = ({ startFetching, disputeTypes, setState }) => {
    const [isFetching, setIsFetching] = useState(true);
  
    const onChange = (label) => {
      const selectedCheckboxes = state.selectedCheckboxes;
      // Find index
      const findIdx = selectedCheckboxes.indexOf(label);
  
      // Index > -1 means that the item exists and that the checkbox is checked
      // and in that case we want to remove it from the array and uncheck it
      if (findIdx > -1) {
        selectedCheckboxes.splice(findIdx, 1);
      } else {
        selectedCheckboxes.push(label);
      }
  
      setState({
        selectedCheckboxes: selectedCheckboxes,
      });
    };
  
    useEffect(() => {
      const fetchDisputeTypes = async () => {
        setIsFetching(true);
        try {
          const all_dispute_types = await startFetching(
            dropdownsSearch("dispute_types_list")
          );
          const response = [
            { value: "Other", label: "other" },
            { value: "Time", label: "time" },
            { value: "Not Me", label: "not me" },
            { value: "Fine Already Paid", label: "fine already paid" },
            { value: "Billing", label: "billing" },
          ];
          const selectedCheckboxes = state.selectedCheckboxes;
  
          // Finding the common elements in both arrays
          response.forEach((ele1) => {
            all_dispute_types.data.forEach(
              (ele2) =>
                ele1.label === ele2.label &&
                !selectedCheckboxes.includes(ele1.label) &&
                selectedCheckboxes.push(ele1.label)
            );
          });
          setState("disputeTypes", response);
          setState({
            selectedCheckboxes: selectedCheckboxes,
          });
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };
      fetchDisputeTypes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    if (isFetching) return <Loader />;
  
    return (
      <div className="d-flex flex-wrap justify-content-center align-items-center mb-4 mt-2">
        {isFetching ? (
          <Loader />
        ) : (
          <>
            {map(disputeTypes, (item) => (
              <Badge
                className={`${styles.badge} d-flex align-items-center w-auto mr-3 mt-3`}
                pill
                key={item.value}
              >
                <div className="mx-2 d-flex align-items-center">
                  <span>{camelize(item.label)}</span>
                </div>
                <input
                  type="checkbox"
                  defaultChecked={selectedCheckboxes.includes(item.label)}
                  value={item.label}
                  onChange={() => onChange(item.label)}
                  selected={selectedCheckboxes.includes(item.label)}
                />
              </Badge>
            ))}
          </>
        )}
      </div>
    );
  };
  
  const Update = (props) => {
    const {
      closeModal,
      isSaving,
      errors,
      setState,
      disputeTypes,
      selectedCheckboxes,
    } = props;
    const formApiRef = useRef();
    const isSavingRef = useRef(isSaving);
  
    const setFormApi = (formApi) => (formApiRef.current = formApi);
    const { addAlertMessages } = useContext(AlertMessagesContext);
  
    const saveDisputeTypes = useCallback(async () => {
      const names = state.selectedCheckboxes;
      await create({ data: { dispute_type: { names } } });
      addAlertMessages(
        [
          {
            type: "Success",
            text: "Changes on citation dispute types were successfully saved ",
            onlyMessage: true,
          },
        ],
        "center"
      );
      closeModal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeModal, addAlertMessages, disputeTypes, selectedCheckboxes]);
  
    useEffect(() => {
      if (isSaving === false && isSavingRef.current === true && isEmpty(errors)) {
        formApiRef.current.setValue("name", "");
      }
      isSavingRef.current = isSaving;
    }, [isSaving, errors]);
  
    useEffect(() => {
      setState("errors", {});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
      <Container className="p-0 mw-100 m-0">
        <p className={`${styles.title} m-0 p-0 text-center`}>
          Citation Dispute Types
        </p>
  
        <Form
          className="row flex-wrap w-100 m-0 justify-content-center"
          onSubmit={saveDisputeTypes}
          getApi={setFormApi}
        >
          <Col className={`${styles.content} mt-4 mx-4`}>
            <DisputeTypes {...props} />
          </Col>
          <Col className="my-4 d-flex justify-content-center" xs={12}>
            <Button
              type="button"
              onClick={() => closeModal()}
              className={`${styles.cancelBtn} ${styles["btn-secondary"]} mr-3`}
            >
              Cancel
            </Button>
            <Button className={`${styles.btn}`} disabled={isSaving} type="submit">
              Save
            </Button>
          </Col>
        </Form>
      </Container>
    );
  };
  
  export default withFetching(Update);