import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'
/* Actions */
import { SET_LIST } from "actions/violations";
/* API */
import { filterFetcher } from "api/violations";
import { search as dropdownsSearch } from "api/dropdowns";
/* Base */
import BasicListToolbar from "components/base/basic_list_toolbar";
import IndexTable from "components/base/table";
/* Helpers */
import { displayUnixTimestamp } from "components/helpers";
import { filterFields } from "components/helpers/fields/violations";
/* Modules */
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";

class Index extends React.Component {
  state = {
    isDropdownFetching: true,
    dropdowns: {
      agencies: [],
      officers: [],
      parkingLots: [],
      statuses: [],
      types: [],
    },
  };

  isFetching = () => {
    const { isResourceFetching } = this.props;
    const { isDropdownFetching } = this.state;
    return isResourceFetching || isDropdownFetching;
  };

  renderRecords = () => {
    const { list, match, history } = this.props;

    return list.map((record, idx) => {
      const { status } = record;
      return (
        <tr
          key={idx}
          onClick={() => history.push(`${match.path}/${record.id}`)}
        >
          <td>{record.id}</td>
          <td onClick={(e) => record.citation_ticket_id && e.stopPropagation()}>
            {record.citation_ticket_id? <Link to={`/dashboard/parking/citation_tickets/${record?.citation_ticket_id}`}>{record?.citation_ticket_id}</Link> : "NA"}
          </td>

          <td>{displayUnixTimestamp(record.created_at)}</td>
          <td>{record.violation_type}</td>
          <td>{record?.parking_lot?.name}</td>
          <td>{record.agency?.name? record.agency?.name : "NA"}</td>
          <td>{record?.officer?.name? record?.officer?.name : "NA"}</td>
          <td>{status && status.charAt(0).toUpperCase() + status.slice(1)}</td>
        </tr>
      );
    });
  };

  setDropdowns = (key, data) =>
    this.setState({ dropdowns: { ...this.state.dropdowns, [key]: data } });

  fetch = () => {
    const { currentUser, startFetching } = this.props;

    Promise.all([
      startFetching(
        dropdownsSearch("agency_list", { admin_id: currentUser.id })
      ).then((response) => this.setDropdowns("agencies", response.data)),
      startFetching(
        dropdownsSearch("admins_by_role-officer", { admin_id: currentUser.id })
      ).then((response) => this.setDropdowns("officers", response.data)),
      startFetching(dropdownsSearch("parking_lot_list")).then((response) =>
        this.setDropdowns("parkingLots", response.data)
      ),
      startFetching(
        dropdownsSearch("tickets_statuses_field")
      ).then((response) => this.setDropdowns("statuses", response.data)),
      startFetching(dropdownsSearch("tickets_types_field")).then((response) =>
        this.setDropdowns("types", response.data)
      ),
    ])
      .catch(this.handleFailed)
      .finally(() => this.setState({ isDropdownFetching: false }));
  }

  refresh = () => {
    this.setState({ isDropdownFetching: true });
    window.location.reload();
  }

  componentDidMount () {
    this.fetch();
  }

  render () {
    const { agencies, officers, parkingLots, statuses, types } = this.state.dropdowns;
    return (
      <IndexTable
        {...this.props}
        isFetching={this.isFetching}
        toolbar={<BasicListToolbar showFilters={true} {...this.props} title="Violations" isRefresh={true} refresh={this.refresh} />}
        filterFields={filterFields(agencies, officers, parkingLots, statuses, types)}
        filterFetcher={filterFetcher}
        resource={resource}
        columns={
          <React.Fragment>
            <th attr="parking_tickets.id">Ticket ID</th>
            <th attr="parking_rules.name">Citation Ticket ID</th>
            <th attr="created_at">Date</th>
            <th attr="parking_rules.name">Violation Type</th>
            <th attr="parking_lots.name">Parking Lot</th>
            <th attr="agencys.name">Agency Name</th>
            <th attr="officers.name">Officer</th>
            <th attr="status">Status</th>
          </React.Fragment>
        }
        renderRecords={this.renderRecords}
      />
    );
  }
}

Index.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const resource = "violation";

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  withFetching(withCurrentUser(Index))
);
